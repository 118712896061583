import React from 'react';
import './MatrixSection.css';
import TokenomicsBar from './TokenomicsBar';

const MatrixSection = () => {
  // Define protocol addresses to display
  const addresses = [
    { name: 'BTBAR Token', address: '0xfA6506F1eAd38Bd74eEf92Eca8Ac93d8ae30163e' },
    { name: 'ToughBar DAO', address: '0x918d036eaeb6e63e11701CFF27d0eD0b8Afa2481' },
    { name: 'Exchange', address: '0x446f698E0aeB8005ED36fCBc4CA1f020c8B979aa' },
    { name: 'Uniswap Pool', address: '0xd4ce6bd471216ab8de7e21f19014354d05d5590a' }
  ];

  // Utility to truncate a long address (first 6 and last 4 characters)
  const truncateAddress = (addr) =>
    addr ? `${addr.slice(0, 6)}...${addr.slice(-4)}` : '';

  // Copy address to clipboard
  const copyToClipboard = (addr) => {
    navigator.clipboard.writeText(addr).catch((err) => console.error('Copy failed', err));
  };

  // Explorer links for Blockscout and BaseScan
  const getExplorerLinks = (address) => ({
    blockscout: `https://base.blockscout.com/address/${address}`,
    basescan: `https://basescan.org/address/${address}`
  });

  return (
    <section className="matrix-section">
      <div className="matrix-grid">
        <h2 className="matrix-title">Protocol Contracts</h2>
        <div className="address-grid">
          {addresses.map(({ name, address }) => {
            const explorers = getExplorerLinks(address);
            return (
              <div
                key={name}
                className="address-card"
                onClick={() => copyToClipboard(address)}
              >
                <div className="address-header">
                  <span className="contract-name">{name}</span>
                </div>
                <div className="contract-address">
                  <span className="address-text">{truncateAddress(address)}</span>
                  <button className="copy-button" title="Copy address">
                    Copy
                  </button>
                </div>
                <div className="explorer-links">
                  <a
                    href={explorers.blockscout}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="explorer-link"
                    title="View on Blockscout"
                  >
                    Blockscout
                  </a>
                  <a
                    href={explorers.basescan}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="explorer-link"
                    title="View on BaseScan"
                  >
                    BaseScan
                  </a>
                </div>
              </div>
            );
          })}
        </div>

        {/* TokenomicsBar displayed between addresses and external links */}
        <TokenomicsBar />

        {/* External action buttons styled as compact buttons */}
        <div className="matrix-links">
          <a
            href="https://app.uniswap.org/#/swap?chain=base&use=V2&outputCurrency=0xfA6506F1eAd38Bd74eEf92Eca8Ac93d8ae30163e"
            className="matrix-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="btn-icon">🔄</span> Trade on Uniswap
          </a>
          <a
            href="https://www.geckoterminal.com/base/pools/0xd4ce6bd471216ab8de7e21f19014354d05d5590a"
            className="matrix-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="btn-icon">📈</span> Live Price Charts
          </a>
          <button className="matrix-button disabled" disabled>
            <span className="btn-icon">📅</span> Lower Layer Events
          </button>
          <a
            href="https://opensea.io/collection/toughbar-agency-hub"
            className="matrix-button opensea-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="btn-icon">🏆</span> Onchain Badges
          </a>
        </div>
      </div>
    </section>
  );
};

export default MatrixSection;
