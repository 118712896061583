import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

function TokenomicsBar() {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    // Fetch BTBAR price from Uniswap and Chainlink
    const fetchPrice = async () => {
      try {
        // Determine provider: use MetaMask on Base, or fallback to public Base RPC
        let provider;
        if (typeof window !== 'undefined' && window.ethereum) {
          const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await web3Provider.getNetwork();
          if (network.chainId === 8453) {
            provider = web3Provider;
          }
        }
        if (!provider) {
          provider = new ethers.providers.JsonRpcProvider('https://mainnet.base.org');
        }

        const BTBAR_ADDRESS = '0xfA6506F1eAd38Bd74eEf92Eca8Ac93d8ae30163e';
        const PAIR_ADDRESS = '0xd4ce6bd471216ab8de7e21f19014354d05d5590a';
        const CHAINLINK_FEED_ADDRESS = '0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70';  // ETH/USD feed on Base Mainnet

        // Contract ABIs for the needed functions
        const pairAbi = [
          'function getReserves() view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
          'function token0() view returns (address)',
          'function token1() view returns (address)'
        ];
        const erc20Abi = ['function decimals() view returns (uint8)'];
        const feedAbi = [
          'function latestAnswer() view returns (int256)',
          'function decimals() view returns (uint8)'
        ];

        // Uniswap V2 pair contract to get reserves
        const pairContract = new ethers.Contract(PAIR_ADDRESS, pairAbi, provider);
        const token0 = (await pairContract.token0()).toLowerCase();
        const token1 = (await pairContract.token1()).toLowerCase();

        // Identify which token is BTBAR vs WETH in the pair
        const btbIsToken0 = (token0 === BTBAR_ADDRESS.toLowerCase());
        const btbReserveIndex = btbIsToken0 ? 0 : 1;
        const wethReserveIndex = btbIsToken0 ? 1 : 0;

        // Fetch token decimals for accurate calculations
        const btbContract = new ethers.Contract(BTBAR_ADDRESS, erc20Abi, provider);
        const wethAddress = btbIsToken0 ? token1 : token0;
        const wethContract = new ethers.Contract(wethAddress, erc20Abi, provider);
        const btbDecimals = await btbContract.decimals();
        const wethDecimals = await wethContract.decimals();

        // Get reserves [reserve0, reserve1] from the pair
        const [reserve0, reserve1] = await pairContract.getReserves();
        const btbReserve = btbReserveIndex === 0 ? reserve0 : reserve1;
        const wethReserve = wethReserveIndex === 0 ? reserve0 : reserve1;

        // Chainlink price feed for ETH/USD
        const feedContract = new ethers.Contract(CHAINLINK_FEED_ADDRESS, feedAbi, provider);
        const latestAnswer = await feedContract.latestAnswer();
        const priceDecimals = await feedContract.decimals();
        const ethPriceUSD = Number(latestAnswer) / 10 ** priceDecimals;

        // Calculate BTBAR price in USD: (WETH reserve * ETH price) / BTBAR reserve
        const btbReserveFloat = Number(ethers.utils.formatUnits(btbReserve, btbDecimals));
        const wethReserveFloat = Number(ethers.utils.formatUnits(wethReserve, wethDecimals));
        const priceUSD = (wethReserveFloat * ethPriceUSD) / (btbReserveFloat || 1);

        setPrice(priceUSD);
      } catch (error) {
        console.error('Error fetching BTBAR price:', error);
      }
    };

    fetchPrice();
    // Refresh price every 60 seconds
    const intervalId = setInterval(fetchPrice, 60000);
    return () => clearInterval(intervalId);
  }, []);

  // Prepare display string for price (with appropriate formatting)
  let displayPrice;
  if (price === null) {
    displayPrice = 'Loading...';
  } else if (price >= 1) {
    displayPrice = '$' + price.toFixed(2);
  } else if (price >= 0.01) {
    displayPrice = '$' + price.toFixed(4);
  } else {
    displayPrice = '$' + price.toFixed(6);
  }

  return (
    <div className="tokenomics-bar">
      BTBAR Price: <strong>{displayPrice}</strong>
    </div>
  );
}

export default TokenomicsBar;
