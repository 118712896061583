import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        8453: "https://base-mainnet.g.alchemy.com/v2/YOUR_ALCHEMY_KEY",
      },
      chainId: 8453,
      bridge: "https://bridge.walletconnect.org",
      qrcodeModalOptions: {
        mobileLinks: ["metamask", "trust", "rainbow", "argent"]
      }
    }
  },
  torus: {
    package: Torus,
    options: {
      network: {
        host: "base-mainnet",
        chainId: 8453,
        networkName: "Base Mainnet"
      },
      showTorusButton: false
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Your DApp Name",
      infuraId: "YOUR_INFURA_ID",
      chainId: 8453,
      darkMode: true
    }
  },
  metamask: {
    package: true // Use injected provider
  }
};

export const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  theme: "dark",
  disableInjectedProvider: false
});
