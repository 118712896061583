import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { web3Modal } from './web3ModalSetup';
import { ethers } from 'ethers';
import Blockies from 'react-blockies';
import logo from '../assets/TBar.svg';
import config from '../config.json';
import { loadProvider, loadNetwork, loadAccount } from '../store/interactions';
import './Navbar.css';

const Navbar = () => {
  const dispatch = useDispatch();
  const provider = useSelector(state => state.provider.connection);
  const chainId = useSelector(state => state.provider.chainId);
  const account = useSelector(state => state.provider.account);
  const balance = useSelector(state => state.provider.balance);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userInitiated, setUserInitiated] = useState(false);

  const toggleMenu = useCallback(async () => {
    setMenuOpen(prev => !prev);
  }, [])

  const disconnectWallet = useCallback(() => {
    dispatch({ type: "ACCOUNT_DISCONNECTED" });
    dispatch({ type: "PROVIDER_DISCONNECT" });
    dispatch({ type: "RESET_TOKENS" });
    dispatch({ type: "RESET_EXCHANGE" });
    window.location.reload();
  }, [dispatch]);

  const connectWallet = useCallback(async () => {
    setUserInitiated(true);
    try {
      const instance = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(instance);
    
      // Handle chain changed
      instance.on("chainChanged", (chainId) => {
        handleNetworkChanged(parseInt(chainId, 16));
      });

      // Handle accounts changed
      instance.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          dispatch({ type: 'ACCOUNT_LOADED', account: accounts[0] });
        } else {
          disconnectWallet();
        }
      });

      // Handle network switch if needed
      const network = await provider.getNetwork();
      if (network.chainId !== 8453) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x2105' }] // Base Mainnet chainId
        });
      }

      dispatch({ type: 'PROVIDER_LOADED', connection: provider });
      await loadAccount(provider, dispatch);

    } catch (error) {
      console.error("Connection error:", error);
      // Handle specific error cases
      if (error.code === 4001) {
        // User rejected connection
        dispatch({ type: 'CONNECTION_REJECTED' });
      }
    }
  }, [dispatch, disconnectWallet]);



  // Add network handler (place this above useEffect)
  const handleNetworkChanged = (chainId) => {
    if (chainId !== 8453) {
      alert('Please switch to Base Mainnet');
      disconnectWallet();
    }
  };

  useEffect(() => {
    const handleChainChanged = () => window.location.reload();
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        disconnectWallet();
      } else if (userInitiated) {
        loadAccount(provider, dispatch);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }
    return () => {
      if (window.ethereum?.removeListener) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [provider, dispatch, disconnectWallet, userInitiated]);

  return (
    <header className="navbar">
      <div className="navbar__brand">
        <img src={logo} alt="BTbar Logo" className="navbar__logo" />
        <h1>Base ToughBar</h1>
      </div>
      <button className="navbar__toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <nav className={`navbar__nav ${menuOpen ? 'active' : ''}`}>
        <a href="#exchange" className="nav__link" onClick={toggleMenu}>DeFi-Exchange</a>
        <a href="#hero" className="nav__link" onClick={toggleMenu}>About Us</a>
        <a
          href="https://opensea.io/collection/toughbar-agency-hub"
          className="nav__link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleMenu}
        >
          NFT
        </a>
        <a href="#dao" className="nav__link" onClick={toggleMenu}>DAO</a>
      </nav>
      {menuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}
      <div className="navbar__account">
        {account ? (
          <>
            <div className="account__info">
              <p className="account__balance">{Number(balance).toFixed(4)} ETH</p>
              <a
                href={config[chainId]?.explorerURL + "/address/" + account}
                target="_blank"
                rel="noopener noreferrer"
                className="account__address"
              >
                {account.slice(0, 5)}...{account.slice(-4)}
              </a>
            </div>
            <Blockies seed={account} size={10} scale={3} className="account__blockies" />
            <button className="disconnect-button" onClick={disconnectWallet}>Disconnect</button>
          </>
        ) : (
          <button className="connect-button" onClick={connectWallet}>Connect Wallet</button>
        )}
      </div>
    </header>
  );
};

export default Navbar;
