import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import config from '../config.json';

import {
  loadProvider,
  loadNetwork,
  loadAccount,
  loadTokens,
  loadExchange,
  loadAllOrders,
  subscribeToEvents
} from '../store/interactions';

import Navbar from './Navbar'
import MatrixSection from './MatrixSection'
import Markets from './Markets'
import Balance from './Balance'
import Order from './Order'
import PriceChart from './PriceChart'
import Transactions from './Transactions'
import Trades from './Trades'
import OrderBook from './OrderBook'
import Alert from './Alert'
import HeroSection from './HeroSection';
import DAOPage from './DAOPage';
import Footer from './Footer';

function App() {
  const dispatch = useDispatch()

  const loadBlockchainData = useCallback(async () => {
    const provider = loadProvider(dispatch)
    const chainId = await loadNetwork(provider, dispatch)

    // Reload page when network changes
    window.ethereum.on('chainChanged', () => {
      window.location.reload()
    })

    // Fetch current account & balance from Metamask when changed
    window.ethereum.on('accountsChanged', () => {
      loadAccount(provider, dispatch)
    })

    const BTbar = config[chainId].BTbar
    const WETH = config[chainId].WETH
    await loadTokens(provider, [BTbar.address, WETH.address], dispatch)

    const exchangeConfig = config[chainId].exchange
    const exchange = await loadExchange(provider, exchangeConfig.address, dispatch)

    loadAllOrders(provider, exchange, dispatch)
    subscribeToEvents(exchange, dispatch)
  }, [dispatch]);

   useEffect(() => {
    loadBlockchainData()
    
    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners('chainChanged')
        window.ethereum.removeAllListeners('accountsChanged')
      }
    }
  }, [loadBlockchainData]);

  return (
    <div>

      <Navbar />
      <MatrixSection />

      <div className="header-spacer" style={{ height: "calc(var(--navbar-height) + 20px)" }}></div>

      <main className="exchange grid" id="exchange">
        <section className="exchange__section--left grid">

          <Markets />

          <Balance />

          <Order />

        </section>
        <section className="exchange__section--right grid">

          <PriceChart />

          <Transactions />

          <Trades />

          <OrderBook />

        </section>
      </main>
      
      <HeroSection id="hero" />
      <DAOPage id="dao" />
      <Footer />
      <Alert />

    </div>
  );
}

export default App;
