import React, { useMemo, useEffect } from 'react';
import './HeroSection.css';
import sampleSVG from '../assets/TBar.svg';
import CircularFeatureContainer from './CircularFeatureContainer';
import FeatureContainer from './FeatureContainer';
import placeholder1 from '../assets/camo_hoody.png';
import placeholder2 from '../assets/Grey_Hoody.png';
import placeholder3 from '../assets/Knight 2.png';

const HeroSection = () => {
  // Preload placeholder images
  const placeholderImages = useMemo(() => [placeholder1, placeholder2, placeholder3], []);
  useEffect(() => {
    placeholderImages.forEach(src => new Image().src = src);
  }, [placeholderImages]);

  return (
    <section className="hero-section">
      <div className="hero-content">
        {/* Header */}
        <h1 className="hero-title">
          Base ToughBar Hub:
          <br />
          <span className="hero-subtitle">Unleash Your Inner DeFi Adventurer</span>
        </h1>
        <img src={sampleSVG} alt="Hero" className="hero-svg" />
        <p className="hero-description">
          Step into a pop-up DeFi meme universe where innovation meets creativity.
          Explore tokenomics, trade seamlessly, and engage with groundbreaking blockchain events.
        </p>
        {/* Event Highlights */}
        <div className="event-highlights">
          <h2 className="section-title">Blockchain Bonanzas on the Horizon</h2>
          <ul className="event-list">
            <li>DeFi Summit 2025 - The Big Apple's Blockchain Extravaganza</li>
            <li>Blockchain Innovators Webinar Series - Mind-Bending Bytes of Wisdom</li>
            <li>ToughBar Annual Conference - Tokyo's Techno-Crypto Spectacular</li>
          </ul>
        </div>
      </div>
      {/* Feature Containers displayed side by side */}
      <div className="features-container">
        <CircularFeatureContainer
          title="ToughBar DAO"
          description="Join our decentralized autonomous organization and help shape the future of ToughBar."
          imageSrc={placeholder1}
          link="/dao"
          isLocked={true}
        />
        <FeatureContainer
          title="ToughBar Spades Game"
          description="Get ready to dive into the ToughBar Spades Game!"
          imageSrc={placeholder2}
          link="#spades-game"
          isLocked={true}
        />
        <FeatureContainer
          title="ToughBar Merch"
          description="Show off your ToughBar flair with exclusive merchandise!"
          imageSrc={placeholder3}
          link="#merch"
          isLocked={true}
        />
      </div>
    </section>
  );
};

export default HeroSection;
